import React from 'react'
import { ActivityIndicator, Text, View } from 'react-native'
import { makeRNCompatibleStyleArr } from '../../utils/makeRNCompatibleStyleArr'
import COLORS from '../../utils/colors'
import defaultStyles from './styles'
import { ButtonProps } from './types'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export const ButtonBase = (props: ButtonProps) => {
    const {
        loading,
        text,
        textStyle,
        icon,
        iconPosition,
        testID,
        activityIndicatorColor,
    } = props

    const { theme } = useTheme()

    const finalTextStyle = makeRNCompatibleStyleArr([
        defaultStyles.text,
        { opacity: loading ? 0.44 : 1 },
        { color: theme?.colors?.text },
        textStyle,
    ])

    const flexDirection = iconPosition === 'left' ? 'row-reverse' : 'row'

    const activityIndicatorTestID = testID
        ? `${testID}-activityIndicator`
        : 'activityIndicator'

    return (
        <View style={[defaultStyles.innerView]}>
            <View
                style={{
                    display: 'flex',
                    flexDirection,
                    alignItems: 'center',
                }}
            >
                <Text style={finalTextStyle}>{text}</Text>
                {(icon || loading) && (
                    <View
                        style={{
                            marginLeft: flexDirection === 'row' && text ? 6 : 0,
                            marginRight:
                                flexDirection === 'row-reverse' && text ? 6 : 0,
                        }}
                    >
                        {loading ? (
                            <ActivityIndicator
                                testID={activityIndicatorTestID}
                                color={
                                    activityIndicatorColor ||
                                    COLORS.COLOR_LIGHTPINK
                                }
                                style={{ paddingLeft: 7 }}
                            />
                        ) : (
                            icon
                        )}
                    </View>
                )}
            </View>
        </View>
    )
}
