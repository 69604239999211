import React, { useRef, useCallback, useMemo } from 'react'
import { Pressable } from 'react-native'
import { ButtonProps } from './types'
import { Platform } from 'react-native'
import { pickSingle } from 'react-native-document-picker'
import LinearGradientComponent from '../LinearGradient'
import defaultStyles from './styles'
import { FileUploaderWeb } from './FileUploaderWeb'
import { ButtonBase } from './ButtonBase'
import COLORS from '../../utils/colors'
import { makeRNCompatibleStyleArr } from '../../utils/makeRNCompatibleStyleArr'
import { useTheme } from '../ThemeProvider/ThemeProvider'

const DEFAULT_GRADIENT_COLORS = [
    COLORS.COLOR_LIGHTBLUE,
    COLORS.COLOR_MEDIUMBLUE,
] as [string, string]

const Button = (props: ButtonProps) => {
    const {
        input,
        onPress,
        testID,
        gradientColors,
        disabled,
        loading,
        style,
        ...restProps
    } = props

    const { theme } = useTheme()

    const inputRef = useRef<HTMLInputElement | null>(null)
    const withGradient = !disabled && gradientColors?.length === 2
    const isDisabled = disabled || loading
    const useFileUploaderWeb = Platform.OS === 'web' && input?.type === 'file'
    const pressableTestID = testID
        ? `${testID}-buttonPressable`
        : 'buttonPressable'

    const propsWithStyles = useMemo(
        () => ({
            loading,
            disabled,
            gradientColors,
            style: makeRNCompatibleStyleArr([
                defaultStyles.button,
                {
                    backgroundColor: disabled
                        ? theme?.colors?.disabled
                        : theme?.colors?.background,
                },
                style,
                disabled && defaultStyles.disable,
            ]),
            ...restProps,
        }),
        [loading, disabled, gradientColors, style, restProps],
    )

    const _onPress = useCallback(async () => {
        if (input?.type !== 'file') {
            return onPress?.()
        }
        if (Platform.OS == 'ios' || Platform.OS == 'android') {
            const res = await pickSingle({
                type: [input.accepts || '*'],
            })
            input?.onFileChange?.(res?.uri)
            return
        }
        inputRef?.current?.click()
    }, [input, inputRef, onPress])

    return withGradient ? (
        <Pressable
            {...propsWithStyles}
            testID={pressableTestID}
            disabled={isDisabled}
            onPress={_onPress}
        >
            <LinearGradientComponent
                style={defaultStyles.gradient}
                colors={gradientColors || DEFAULT_GRADIENT_COLORS}
            >
                {useFileUploaderWeb && (
                    <FileUploaderWeb
                        testID={testID}
                        input={input}
                        inputRef={inputRef}
                    />
                )}
                <ButtonBase {...propsWithStyles} />
            </LinearGradientComponent>
        </Pressable>
    ) : (
        <Pressable
            {...propsWithStyles}
            testID={pressableTestID}
            disabled={isDisabled}
            onPress={_onPress}
        >
            {useFileUploaderWeb && (
                <FileUploaderWeb
                    testID={testID}
                    input={input}
                    inputRef={inputRef}
                />
            )}
            <ButtonBase {...propsWithStyles} />
        </Pressable>
    )
}

export default Button
