import { StyleSheet } from 'react-native';
import { BuilderTheme } from '../ThemeProvider/themes';

const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    calendarStyle: {
        marginTop: 2,
        backgroundColor: theme?.colors?.background,
        minHeight: 350,
        shadowColor: theme?.colors?.text,
        maxWidth: 400,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        borderRadius: 8,
    },
    imgStyle: {
        height: '100%',
        width: '100%',
        resizeMode: 'stretch',
    },
    renderArrowStyle: { 
        height: 20, 
        width: 20 
    },
    container: {
        borderWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        backgroundColor: theme?.colors?.background,
        borderRadius: 8,
        borderColor: theme?.colors?.border,
        height: 40,
    },
    textInputStyle: {
        fontSize: 16,
        fontWeight: theme?.typography?.p?.fontWeight,
        padding: 10,
        minWidth: 200,
        backgroundColor: 'transparent',
        color: theme?.colors?.text,
    },
    leftIcon: {
        marginLeft: 8,
        color: theme?.colors?.text,
        height: 18,
        width: 18,
        textAlign: 'center',
    },
});

export default createStyles;

